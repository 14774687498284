import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/workspace/inbox-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "collections"
    }}>{`Collections`}</h1>
    <p>{`Here you can find information on various collections based features in the InboxHealth app.`}</p>
    <h2 {...{
      "id": "ignored_by_collections"
    }}>{`ignored_by_collections`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{` property on a `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{` is used to denote if a `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{` is
to be ignored by collections. If this property is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` on the `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{`, any `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{` on an `}<inlineCode parentName="p">{`Invoice`}</inlineCode>{` that is
associated with this `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{` will have a computed boolean when retrieved via the API that denotes if it's balance is collectable via
a boolean `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{`. Additionally, as long as a given `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{` on an `}<inlineCode parentName="p">{`Invoice`}</inlineCode>{` has an equivalent `}<inlineCode parentName="p">{`service_code`}</inlineCode>{` to that of a `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{` which is set to be `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{`,
even if the `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{` is not directly associated with the `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{` it will be correctly marked with the corresponding `}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{`'s value.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{` is `}<inlineCode parentName="p">{`true`}</inlineCode>{` for a `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{`, this means that it's `}<inlineCode parentName="p">{`total_charge_amount_cents`}</inlineCode>{` should be subtracted from the balance and it's `}<inlineCode parentName="p">{`covered_amount_cents`}</inlineCode>{` should be added to the balance.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`The general idea is that items that are `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{` will 'zero out' other line items, and add back value to the invoice's balance as collectable.`}</p>
    </blockquote>
    <p><strong parentName="p">{`This is not reflected in InboxHealth's balance for the patient, it must be done manually when computing a patient's collectable balance.`}</strong></p>
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <p>{`When retrieving a `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{` from the Partner API, it will include a boolean `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{`. This can be used to determine how the line item
should be used to calculate the patient's collectable balance. If it is to be ignored (`}<inlineCode parentName="p">{`ignored_by_collections == true`}</inlineCode>{`), it should be subtracted from that patient's balance for collection purposes.`}</p>
    <p><inlineCode parentName="p">{`GET /partner/v2/line_items/:id`}</inlineCode></p>
    <p>{`Example Response (observe `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{` boolean at the bottom):`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "line_item": {
        "id": 1,
        "invoice_id": 1,
        "appointment_id": null,
        "service_code": null,
        "description": "Some Procedure",
        "created_at": "2021-04-01T16:34:10.549Z",
        "updated_at": "2021-04-01T16:34:10.549Z",
        "date_of_service": "2021-04-01T00:00:00.000Z",
        "total_charge_amount_cents": 0,
        "covered_amount_cents": 0,
        "saved_line_item_id": 1,
        "tax_amount_cents": 0,
        "quantity": 1,
        "discount_amount_cents": 0,
        "discount_percent": 0.0,
        "tax_percent": 0.0,
        "insurance_balance_cents": 0,
        "write_off_id": null,
        "insurance_owed_amount_cents": null,
        "ignored_by_collections": true
    }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`ignored_by_collections`}</inlineCode>{` boolean will be included on any endpoint that returns a collection of `}<inlineCode parentName="p">{`LineItems`}</inlineCode>{`, either directly (like above) or indirectly (e.g. when retrieving an invoice, it will be included in the `}<inlineCode parentName="p">{`line_items`}</inlineCode>{` collection)`}</p>
    <h2 {...{
      "id": "changing-setting-in-app"
    }}>{`Changing setting in app`}</h2>
    <p>{`First, select the 'settings' menu in the application using the left side navigation menu:`}</p>
    <p><img alt="Settings menu" src={require("../../static/images/collections/ignored_by_collections/settings_menu.jpg")} /></p>
    <p>{`On the settings page, select 'additional' in the top menu navigation, and then 'services' from the additonal options that present:`}</p>
    <p><img alt="Settings menu" src={require("../../static/images/collections/ignored_by_collections/services_settings_page.jpg")} />{`
Route: `}<inlineCode parentName="p">{`practices/practice_settings/advanced_settings/services`}</inlineCode></p>
    <p>{`You can click the 'Ignored by collections' toggle to change a service (`}<inlineCode parentName="p">{`SavedLineItem`}</inlineCode>{`) so that it is ignored by collections.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      